import React, { LegacyRef, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';
import { cx } from '../../helpers/utils';
import { Empty, ErrorBoundary } from '../../app/Common/ErrorBoundary';
import { tv } from 'tailwind-variants';
import { Spinner } from '../Spinner';

const buttonClasses = tv({
  base: 'inline-flex h-fit w-fit items-center whitespace-nowrap rounded-lg text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
  variants: {
    color: { primary: '', success: '', error: '', warning: '', info: '' },
    variant: {
      filled: '',
      soft: '',
      outlined: '',
      light:
        'bg-white font-medium text-slate-800 shadow-[0px_1px_1px_rgba(0,0,0,0.09)] ring-1 ring-slate-300 ring-inset hover:bg-slate-50',
      naked: 'bg-transparent font-semibold hover:bg-slate-100',
      icon: 'rounded-full font-medium text-slate-700 hover:bg-slate-100',
      text: 'font-medium text-indigo-600 hover:text-indigo-500',
      secondary: 'font-medium',
      secondaryOutline:
        'inline-flex h-8 cursor-pointer items-center gap-1 rounded-lg bg-white px-2 py-0 font-medium text-slate-700 text-sm shadow-sm ring-1 ring-slate-200 ring-inset hover:bg-slate-50',
    },
    size: {
      text: 'gap-x-1 px-0 py-0',
      tiny: 'gap-x-1.5 px-1.5 py-1 text-xs',
      small: 'gap-x-1.5 px-2.5 py-1.5',
      medium: 'gap-x-1.5 px-3 py-2',
      large: 'gap-x-2 px-3 py-2.5',
      icon: 'h-4 w-4',
    },
    textSize: {
      xs: 'text-xs',
    },

    // Booleans
    hasValue: { true: '' },
    fullWidth: { true: 'w-full justify-center' },
    loading: { true: 'saturate-75' },
  },
  compoundVariants: [
    {
      variant: 'secondaryOutline',
      hasValue: true,
      class:
        'border-brand-100 bg-brand-25 text-brand hover:border-brand-200 hover:bg-brand-50',
    },

    // Naked
    { variant: 'naked', color: 'primary', class: 'text-slate-700' },
    { variant: 'naked', color: 'success', class: 'text-green-700' },
    { variant: 'naked', color: 'error', class: 'text-red-700' },
    { variant: 'naked', color: 'warning', class: 'text-yellow-700' },
    { variant: 'naked', color: 'info', class: 'text-blue-700' },

    // Secondary
    {
      variant: 'secondary',
      color: 'primary',
      class: 'bg-slate-100 hover:enabled:bg-slate-200/60',
    },
    {
      variant: 'secondary',
      color: 'success',
      class: 'bg-green-100 hover:enabled:bg-green-200',
    },
    {
      variant: 'secondary',
      color: 'error',
      class: 'bg-red-100 hover:enabled:bg-red-200',
    },
    {
      variant: 'secondary',
      color: 'warning',
      class: 'bg-yellow-100 hover:enabled:bg-yellow-200',
    },
    {
      variant: 'secondary',
      color: 'info',
      class: 'bg-slate-100 hover:enabled:bg-slate-200',
    },

    // Outlined
    {
      variant: 'outlined',
      color: 'primary',
      class:
        'bg-white font-semibold text-indigo-600 shadow-sm ring-1 ring-indigo-600 ring-inset hover:bg-indigo-100',
    },
    {
      variant: 'outlined',
      color: 'success',
      class:
        'bg-white font-semibold text-green-900 shadow-sm ring-1 ring-green-300 ring-inset hover:bg-green-100',
    },
    {
      variant: 'outlined',
      color: 'error',
      class:
        'bg-white font-semibold text-red-900 shadow-sm ring-1 ring-red-300 ring-inset hover:bg-red-100',
    },
    {
      variant: 'outlined',
      color: 'warning',
      class:
        'bg-white font-semibold text-yellow-900 shadow-sm ring-1 ring-yellow-300 ring-inset hover:bg-yellow-100',
    },
    {
      variant: 'outlined',
      color: 'info',
      class:
        'bg-white font-semibold text-blue-900 shadow-sm ring-1 ring-blue-300 ring-inset hover:bg-blue-100',
    },

    // Soft
    {
      variant: 'soft',
      color: 'primary',
      class:
        'bg-indigo-50 font-medium text-indigo-600 shadow-sm hover:bg-indigo-100',
    },
    {
      variant: 'soft',
      color: 'success',
      class:
        'bg-green-50 font-medium text-green-600 shadow-sm hover:bg-green-100',
    },
    {
      variant: 'soft',
      color: 'error',
      class: 'bg-red-50 font-medium text-red-600 shadow-sm hover:bg-red-100',
    },
    {
      variant: 'soft',
      color: 'warning',
      class:
        'bg-yellow-50 font-medium text-yellow-600 shadow-sm hover:bg-yellow-100',
    },
    {
      variant: 'soft',
      color: 'info',
      class: 'bg-blue-50 font-medium text-blue-600 shadow-sm hover:bg-blue-100',
    },

    // Filled
    {
      variant: 'filled',
      color: 'primary',
      class:
        'bg-indigo-600 font-medium text-white shadow-sm hover:bg-indigo-600/95 focus-visible:outline-indigo-600',
    },
    {
      variant: 'filled',
      color: 'success',
      class:
        'bg-green-600 font-medium text-white shadow-sm hover:bg-green-600/95 focus-visible:outline-green-600',
    },
    {
      variant: 'filled',
      color: 'error',
      class:
        'bg-red-600 font-medium text-white shadow-sm hover:bg-red-600/95 focus-visible:outline-red-600',
    },
    {
      variant: 'filled',
      color: 'warning',
      class:
        'bg-yellow-600 font-medium text-white shadow-sm hover:bg-yellow-600/95 focus-visible:outline-yellow-600',
    },
    {
      variant: 'filled',
      color: 'info',
      class:
        'bg-blue-600 font-medium text-white shadow-sm hover:bg-blue-600/95 focus-visible:outline-blue-600',
    },
  ],
});

export type ButtonProps = {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  variant?:
    | 'filled'
    | 'soft'
    | 'outlined'
    | 'light'
    | 'naked'
    | 'icon'
    | 'text'
    | 'secondary'
    | 'secondaryOutline';
  size?: 'text' | 'tiny' | 'small' | 'medium' | 'large' | 'icon';
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement>) => void)
    | ((event: React.MouseEvent<HTMLButtonElement>) => boolean)
    | ((event: React.MouseEvent<HTMLButtonElement>) => Promise<void>)
    | ((event: React.MouseEvent<HTMLButtonElement>) => Promise<boolean>);
  autoFocus?: boolean;
  href?: string;
  loading?: boolean;
  color?: 'primary' | 'success' | 'error' | 'warning' | 'info';
  disabled?: boolean;
  target?: '_blank';
  download?: string;
  textSize?: 'xs';
  fullWidth?: boolean;
  buttonTeamMenu?: boolean;
  className?: string;
  hasValue?: boolean;
  id?: string;
  type?: 'button' | 'submit';
  ariaLabel?: string;
};

export type ActionButonProps = Pick<
  ButtonProps,
  'onClick' | 'loading' | 'color'
>;
/**
 * Button component
 */
export const Button = React.forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(function Button(
  {
    children,
    startIcon,
    variant = 'filled',
    color = 'primary',
    size = 'medium',
    disabled,
    onClick,
    autoFocus = false,
    href,
    loading,
    target,
    endIcon,
    download,
    textSize,
    fullWidth,
    className,
    hasValue,
    id,
    type = 'button',
    ariaLabel,
  },
  ref: LegacyRef<HTMLButtonElement>
) {
  const navigate = useNavigate();

  return (
    <ErrorBoundary category="tButton" ErrorComponent={Empty}>
      <button
        ref={ref}
        autoFocus={autoFocus}
        type={type}
        id={id}
        aria-label={ariaLabel}
        className={buttonClasses({
          className,
          variant,
          color,
          size,
          textSize,
          fullWidth,
          hasValue,
          loading,
        })}
        onClick={async (e) => {
          if (loading || disabled) return;

          if (onClick) {
            const clickResult = onClick(e);
            const returnValue =
              clickResult instanceof Promise ? await clickResult : clickResult;
            if (returnValue === false) return;
          }

          if (download && href) {
            const link = document.createElement('a');
            link.href = href;
            link.download = download;
            link.click();
            URL.revokeObjectURL(link.href);
            document.removeChild(link);
          } else if (href) {
            if (target === '_blank') {
              window.open(href, '_blank');
            } else if (href.startsWith('https://')) {
              location.assign(href);
            } else {
              const targetRef = href.startsWith('/#')
                ? href.replace('/#', '')
                : href;
              navigate(targetRef);
            }
          }
        }}
        disabled={disabled ?? loading}
      >
        {loading ? (
          <Spinner className="!text-inherit stroke-current" size="1rem" />
        ) : (
          startIcon && <span>{startIcon}</span>
        )}
        {children}
        {endIcon && <span>{endIcon}</span>}
      </button>
    </ErrorBoundary>
  );
});

/**
 * Action Button
 */
export const ActionButton: React.FC<PropsWithChildren<ActionButonProps>> = ({
  children,
  onClick,
  loading,
  color = 'primary',
}) => {
  return (
    <ErrorBoundary category="actionButton" ErrorComponent={Empty}>
      <button
        className={buttonClasses({
          size: 'small',
          variant: 'filled',
          textSize: 'xs',
          loading,
          color,
        })}
        onClick={onClick}
      >
        {loading && <TLoading size="small" />}
        {children}
      </button>
    </ErrorBoundary>
  );
};

/**
 * loading indicator
 */
export const TLoading: React.FC<{
  size?: 'small' | 'medium' | 'large';
  color?: string;
}> = ({ size = 'medium', color }) => {
  const defaultClasses = 'animate-spin';
  const loadingSizeClasses: Record<NonNullable<typeof size>, string> = {
    small: 'size-4',
    medium: 'w-6 h-6',
    large: 'w-8 h-8',
  };

  return (
    <span translate="no">
      <svg
        className={cx(defaultClasses, loadingSizeClasses[size], color)}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </span>
  );
};
